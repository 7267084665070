* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  background: #eee;
}
.h-full {
  height: 100vh;
}
.mt-2 {
  margin-top: 2rem;
}
.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}
.flex {
    display: flex;
}
.jcc {
    justify-content: center;
}
.aic {
    align-items: center;
}
.col {
    flex-direction: column;
}
.jcsb {
  justify-content: space-between;
}
#ft {
  width: 15rem;
}
#ft path:nth-child(1) {
  stroke-dasharray: 3929px;
  stroke-dashoffset: 3929;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.1s;
}
#ft path:nth-child(2) {
  stroke-dasharray: 3421px;
  stroke-dashoffset: 3421px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.1s;
}
#logo {
  margin-left: 2rem;
  transition: 0.5s;
}
#logo path {
  stroke: royalblue;
}
#logo path:nth-child(1) {
  stroke-dasharray: 384px;
  stroke-dashoffset: 384px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.1s;
}
#logo path:nth-child(2) {
  stroke-dasharray: 349px;
  stroke-dashoffset: 349px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.3s;
}
#logo path:nth-child(3) {
  stroke-dasharray: 214px;
  stroke-dashoffset: 214px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.5s;
}
#logo path:nth-child(4) {
  stroke-dasharray: 339px;
  stroke-dashoffset: 339px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.7s;
}
#logo path:nth-child(5) {
  stroke-dasharray: 395px;
  stroke-dashoffset: 395px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 0.9s;
}
#logo path:nth-child(6) {
  stroke-dasharray: 255px;
  stroke-dashoffset: 255px;
  animation: logoDraw 2s ease-in-out forwards;
  animation-delay: 1.1s;
}
@keyframes logoDraw {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes deleteLogo {
  to {
    opacity: 0;
    display: none;
  }
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@font-face {
    font-family: "Nightmare";
    src: url("../fonts/NightmarePills-BV2w.ttf");
}
//.MuiPaper-elevation ul{
//  height: 300px;
//}

#combo-box-container {
  float: right;
  display: block;
  //margin: 8px 0 32px 8px;
}

 .client.true::before {
        content: 'Black list';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex-center();
        font-family: Nightmare, serif;
        font-size: 4rem;
        color: red;
        transform: rotateZ(-40deg);
    }

    // .client:hover {
    //    -webkit-transform: scale(1.1);
    //    transform: scale(1.1);
    //    background: $neumorphBg;
    //    box-shadow: 13px 13px 20px $neumorphDarkShadow,
    //                -13px -13px 20px $neumorphLightShadow;
    //
    //    .userLogIn {
    //        background: $neumorphBg;
    //    }
    //    .userLogIn:hover {
    //        @include neumorphBox();
    //        border-radius: 0.35rem;
    //    }
    //}